<template>
  <IndexCom ref="indexCom" @changeIndex="changeIndex">
<!--    <template v-slot>-->
      <component :is="indexComponent[isItemActive]" @changeIndex="changeIndex"></component>
<!--    </template>-->
  </IndexCom>
</template>

<script>
  import IndexCom from '@/components/IndexCom.vue'
  import GoodDetail from '@/components/GoodDetail.vue'

  export default {
    name: "good",
    data(){
      return {
        goodOrIndex: true,
        indexComponent:[
          'GoodDetail',
          'PatentGoodPay'
        ],
        isItemActive:0
      }
    },
    components: {
      'IndexCom': IndexCom,
      'ComponentIndex': () => import('@/components/index/ComponentIndex'),
      'ScienceServices': () => import('@/components/index/ScienceServices'),
      'PolicyIndex': () => import('@/components/index/PolicyIndex'),
      'ExaminationIndex': () => import('@/components/index/ExaminationIndex'),
      'SciencePolicy': () => import('@/components/index/SciencePolicy'),
      'QiIndex': () => import('@/components/index/QiIndex'),
      'PatentTrusteeshipIndex': () => import('@/components/index/PatentTrusteeshipIndex'),
      'MediaReport': () => import('@/components/index/MediaReport'),
      'DemandHall': () => import('@/components/index/DemandHall'),
      'EncyclopediaOfIntellectualProperty': () => import('@/components/index/EncyclopediaOfIntellectualProperty'),
      'ResourceRetrieval': () => import('@/components/index/ResourceRetrieval'),
      'PatentSearch': () => import('@/components/index/PatentSearch'),
      'GoodDetail': () => import('@/components/GoodDetail'),
      'PatentSupermarket': () => import('@/components/index/PatentSupermarket'),
      'PatentGoodPay': () => import('@/components/index/PatentGoodPay')

    },
    methods:{
      changeIndex(index){

        this.isItemActive =  ~~index;
      }
    }
  }
</script>

<style scoped>

</style>
